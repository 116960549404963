<template>
  <!-- Main content with padding for fixed bar -->
  <div class="q-pa-md content-wrapper">
    <!-- Fixed top bar -->
    <div class="fixed-top-bar">
      <div class="stats">
        <q-chip color="#f5f5f5" textColor="#151515">{{ selectedImages.length }} selected</q-chip>
        <span class="separator">|</span>
        <span>{{ images.length }} / {{ pagination.total }} images</span>
      </div>
      <div class="actions">
        <q-btn :disabled="selectedImages.length === 0" color="#f5f5f5" textColor="#151515" icon="o_settings" :label="`Batch Resize`" @click="handleBatchDownload" style="margin-right: 12px" />
        <q-btn
          :disabled="selectedImages.length === 0"
          color="primary"
          textColor="#151515"
          icon="o_cloud_download"
          :label="`Batch Download`"
          @click="handleBatchDownload"
          :loading="isBatchDownloading"
          :percentage="downloadProgress"
          :dark-percentage="downloadProgress"
          style="margin-right: 12px"
          dark
        />
      </div>
    </div>
    <div class="images-grid">
      <div v-for="image in images" :key="image.id" class="grid-item" :class="[getImageClass(image), { selected: isSelected(image) }]" @click="toggleSelection(image)">
        <div class="selection-overlay" v-if="isSelected(image)">
          <q-icon name="check_circle" size="32px" color="white" />
        </div>

        <img
          v-lazy="{
            src: image.preview_url,
            loading: image.placeholder_url,
            error: image.placeholder_url
          }"
          class="grid-image"
        />

        <div class="image-overlay">
          <div class="action-buttons">
            <q-btn color="black" flat round class="action-btn" @click.stop="handleDownload(image)" :loading="downloadingStates[image.id]">
              <q-icon name="o_download" size="18px" />
              <q-tooltip>Download Image</q-tooltip>
            </q-btn>

            <q-btn color="black" flat round class="action-btn" @click.stop="handleCopyLink(image.url)">
              <q-icon name="o_link" size="18px" />
              <q-tooltip>Copy Link</q-tooltip>
            </q-btn>

            <q-btn color="black" flat round class="action-btn" @click.stop="shareToFacebook(image)">
              <q-icon name="fab fa-facebook-f" size="18px" />
              <q-tooltip>Share on Facebook</q-tooltip>
            </q-btn>

            <q-btn color="black" flat round class="action-btn" @click.stop="shareToInstagram(image)">
              <q-icon name="fab fa-instagram" size="18px" />
              <q-tooltip>Share on Instagram</q-tooltip>
            </q-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const ASPECT_RATIO_THRESHOLD = 1.5;
const TALL_RATIO_THRESHOLD = 0.7;

export default {
  name: 'ImagesGrid',
  props: {
    images: {
      type: Array,
      required: true,
      default: () => []
    },
    pagination: {}
  },
  data() {
    return {
      downloadingStates: {},
      selectedImages: [],
      isBatchDownloading: false,
      downloadProgress: 0
    };
  },
  mounted() {
    console.log('Mounted Grid Props', this.pagination);
  },
  methods: {
    isSelected(image) {
      return this.selectedImages.some(selected => selected.id === image.id);
    },

    toggleSelection(image) {
      if (this.isSelected(image)) {
        this.selectedImages = this.selectedImages.filter(selected => selected.id !== image.id);
      } else {
        this.selectedImages.push(image);
      }
    },

    async handleBatchDownload() {
      if (this.selectedImages.length === 0) return;
      this.isBatchDownloading = true;
      this.downloadProgress = 0;

      try {
        const JSZip = require('jszip');
        const zip = new JSZip();
        const totalImages = this.selectedImages.length;
        let completedImages = 0;

        await Promise.all(
          this.selectedImages.map(async image => {
            const response = await fetch(image.url);
            const blob = await response.blob();
            const filename = image.url.split('/').pop().split('?')[0];
            zip.file(filename, blob);
            completedImages++;
            this.downloadProgress = Math.round((completedImages / totalImages) * 100);
          })
        );

        const content = await zip.generateAsync({ type: 'blob' });
        const url = window.URL.createObjectURL(content);
        const a = document.createElement('a');
        a.href = url;
        a.download = `images-${new Date().getTime()}.zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (error) {
        console.error('Error batch downloading images:', error);
        this.$q.notify({
          type: 'negative',
          message: 'Error downloading images'
        });
      } finally {
        this.isBatchDownloading = false;
        this.downloadProgress = 0;
      }
    },

    getImageRatio(image) {
      return image.aspect_ratio || image.width / image.height;
    },

    getImageClass(image) {
      const aspectRatio = this.getImageRatio(image);
      if (aspectRatio > ASPECT_RATIO_THRESHOLD) {
        return 'grid-item--landscape';
      } else if (aspectRatio < TALL_RATIO_THRESHOLD) {
        return 'grid-item--portrait';
      }
      return '';
    },

    async handleDownload(image) {
      this.$set(this.downloadingStates, image.id, true);
      try {
        const response = await fetch(image.url);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = image.url.split('/').pop().split('?')[0];
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (error) {
        console.error('Error downloading image:', error);
      } finally {
        this.$set(this.downloadingStates, image.id, false);
      }
    },

    async handleCopyLink(imageUrl) {
      try {
        await navigator.clipboard.writeText(imageUrl);
        this.$q.notify({
          type: 'positive',
          message: 'Link copied to clipboard'
        });
      } catch (error) {
        console.error('Error copying to clipboard:', error);
      }
    },

    shareToFacebook(image) {
      const url = encodeURIComponent(image.preview_url);
      window.open(`https://www.facebook.com/share.php?u=${url}`, '_blank');
    },

    shareToInstagram(image) {
      this.handleCopyLink(image.url);
      this.$q.notify({
        message: 'Image link copied! Open Instagram to share',
        color: 'info'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.fixed-top-bar {
  position: fixed;
  height: 60px;
  width: calc(100vw - 300px);
  background: white;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .stats {
    font-size: 14px;
    color: #666;

    .separator {
      margin: 0 8px;
    }
  }
}

.content-wrapper {
  position: relative;
}

.images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: 300px;
  grid-auto-flow: dense;
  gap: 16px;
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
}

.grid-item {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;

  &.selected {
    transform: scale(0.98);

    .grid-image {
      filter: brightness(0.7);
    }
  }

  &:hover .image-overlay {
    opacity: 1;
    pointer-events: auto;
  }

  &--landscape {
    grid-column: span 2;
    grid-row: span 2;
    @media (max-width: 900px) {
      grid-column: span 1;
      grid-row: span 1;
    }
  }

  &--portrait {
    grid-row: span 2;
  }
}

.selection-overlay {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
  pointer-events: none;
}

.grid-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  transition: filter 0.3s ease;
  content-visibility: auto;
  contain: content;
  contain-intrinsic-size: 300px;

  &[lazy='loading'] {
    filter: blur(10px);
  }

  &[lazy='loaded'] {
    filter: blur(0);
    transition: filter 0.7s;
    will-change: filter;
  }
}

.image-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.action-buttons {
  display: flex;
  gap: 4px;
  margin-top: auto;
  padding: 8px;
  align-items: center;
  justify-content: center;
  padding-bottom: 12px;
}

.action-btn {
  width: 42px;
  height: 42px;
  background-color: rgba(255, 255, 255, 0.3);
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.1);
    color: var(--main-btn-color) !important;
  }

  &:active {
    transform: scale(0.95);
  }
}

@media (max-width: 600px) {
  .images-grid {
    grid-template-columns: 1fr;
    grid-auto-rows: 250px;
  }
}
</style>
