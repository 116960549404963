<template>
  <div class="gallery-container">
    <div class="sidebar q-pa-md">
      <!-- Business Context Search -->
      <q-input v-model="metadataSearchQuery" outlined placeholder="Search shoot details..." class="q-mb-sm" clearable>
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>
      <div class="input-caption">Search by shoot ID, name, notes, outlet details, or filename</div>

      <!-- Visual Search -->
      <q-input v-model="visualSearchQuery" outlined placeholder="Describe what you see..." class="q-mb-sm" clearable>
        <template v-slot:append>
          <q-icon name="image_search" />
        </template>
      </q-input>
      <div class="input-caption" style="border-bottom: 1px solid rgba(0, 0, 0, 0.35); padding-bottom: 12px">Search by visual content (e.g. "person wearing a red jacket")</div>

      <!-- Date Range Filter -->
      <!-- <div class="input-caption" style="margin-top: 12px">Filter images by shoot date range</div> -->
      <q-date v-model="dateRange" range minimal today-btn @input="handleDateChange" class="calendar" />

      <!-- Services Filter -->
      <q-expansion-item expand-separator icon="o_category" label="Category" style="text-align: left">
        <q-option-group v-model="selectedServices" :options="serviceOptions" type="checkbox" @input="handleServicesChange" class="checkbox" style="text-align: left" />
      </q-expansion-item>

      <!-- Aspect Ratio Filter -->
      <q-expansion-item expand-separator icon="o_aspect_ratio" label="Aspect Ratio" style="text-align: left">
        <q-option-group v-model="selectedAspectRatios" :options="aspectRatioOptions" type="checkbox" @input="handleAspectRatioChange" class="checkbox" />
      </q-expansion-item>

      <!-- Clear Filters -->
      <q-btn outline color="grey" label="Clear Filters" class="full-width q-mt-lg" @click="clearFilters" :disable="!hasActiveFilters" />
    </div>

    <div class="gallery-content" @scroll="handleScroll" ref="scrollContainer">
      <!-- Initial Loading State -->
      <div v-if="imagesQuery.isLoading.value" class="row justify-center items-center full-height">
        <q-spinner-dots color="#151515" size="80px" />
      </div>

      <!-- Error State -->
      <div v-else-if="imagesQuery.isError.value" class="row justify-center items-center full-height">
        <div class="column items-center">
          <div class="text-negative q-mb-md">Error loading images</div>
          <q-btn color="primary" label="Retry" @click="imagesQuery.refetch" />
        </div>
      </div>
      <div v-else-if="!imagesQuery.isFetching.value && (!allImages.length || allImages.length === 0)" class="row justify-center items-center full-height">
        <div class="column items-center">
          <q-icon name="photo_library" size="48px" color="grey" class="q-mb-sm" />
          <div class="text-subtitle1 text-grey">No images found</div>
          <div class="text-caption text-grey q-mt-sm">Please try adjusting your search filters</div>
        </div>
      </div>

      <!-- Content -->
      <template v-else>
        <ImagesGrid v-if="allImages.length && currentPagination.length !== 0" :images="allImages" :pagination="currentPagination" />

        <!-- Empty State -->
        <div v-else-if="!imagesQuery.isFetching.value" class="row justify-center items-center full-height">
          <div class="text-subtitle1 text-grey">No images found</div>
        </div>

        <!-- Bottom Loading Spinner -->
        <div v-if="shouldShowLoadingSpinner" class="row justify-center q-my-md">
          <q-spinner-dots color="#151515" size="60px" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api';
import { useInfiniteQuery } from '@tanstack/vue-query';
import debounce from 'lodash/debounce';
import { ShootsApi } from '@api/index';
import ImagesGrid from '@components/ImagesGrid.vue';

export default {
  name: 'Gallery',
  components: { ImagesGrid },

  setup(props, { root }) {
    const store = root.$store;
    const scrollContainer = ref(null);
    const dateRange = ref({ from: null, to: null });
    const selectedServices = ref([]);
    const selectedAspectRatios = ref([]);
    const metadataSearchQuery = ref('');
    const debouncedMetadataQuery = ref('');
    const visualSearchQuery = ref('');
    const debouncedVisualQuery = ref('');

    const services = computed(() => store.getters['services/getServices']);
    const serviceOptions = computed(() =>
      services.value.map(service => ({
        label: service.name,
        value: service.id
      }))
    );

    const aspectRatioOptions = [
      { label: 'Square', value: 'square' },
      { label: 'Landscape', value: 'landscape' },
      { label: 'Portrait', value: 'portrait' }
    ];

    const hasActiveFilters = computed(() => {
      return (
        dateRange.value.from !== null ||
        dateRange.value.to !== null ||
        selectedServices.value.length > 0 ||
        selectedAspectRatios.value.length > 0 ||
        metadataSearchQuery.value.trim() !== '' ||
        visualSearchQuery.value.trim() !== ''
      );
    });

    // Create debounced functions
    const updateDebouncedMetadata = debounce(value => {
      debouncedMetadataQuery.value = value;
    }, 300);

    const updateDebouncedVisual = debounce(value => {
      debouncedVisualQuery.value = value;
    }, 300);

    // Watch both queries
    watch(metadataSearchQuery, updateDebouncedMetadata);
    watch(visualSearchQuery, updateDebouncedVisual);

    // Update filters computed
    const filters = computed(() => ({
      date: {
        from: dateRange.value.from,
        to: dateRange.value.to
      },
      services: selectedServices.value,
      aspectRatios: selectedAspectRatios.value,
      metadataSearchQuery: debouncedMetadataQuery.value.trim(),
      visualSearchQuery: debouncedVisualQuery.value.trim()
    }));

    const imagesQuery = useInfiniteQuery({
      queryKey: ['images', filters],
      queryFn: async ({ pageParam = null }) => {
        const payload = {
          filters: {
            ...(filters.value.date.from || filters.value.date.to ? { date: filters.value.date } : {}),
            ...(filters.value.services?.length ? { services: filters.value.services } : {}),
            ...(filters.value.aspectRatios?.length ? { aspectRatios: filters.value.aspectRatios } : {}),
            ...(filters.value.metadataSearchQuery ? { metadataSearchQuery: filters.value.metadataSearchQuery } : {}),
            ...(filters.value.visualSearchQuery ? { visualSearchQuery: filters.value.visualSearchQuery } : {})
          },
          pagination: {
            limit: 20,
            cursor: pageParam
          }
        };
        return await ShootsApi.getImages(payload);
      },
      getNextPageParam: lastPage => {
        return lastPage.pagination.hasMore ? lastPage.pagination.nextCursor : undefined;
      },
      initialPageParam: null
    });

    const currentPagination = computed(() => {
      if (!imagesQuery.data.value?.pages.length) return null;
      return imagesQuery.data.value.pages[imagesQuery.data.value.pages.length - 1].pagination;
    });

    const allImages = computed(() => {
      if (!imagesQuery.data.value) return [];
      return imagesQuery.data.value.pages.flatMap(page => page.images);
    });

    const shouldShowLoadingSpinner = computed(() => {
      return imagesQuery.isFetching.value && imagesQuery.hasNextPage.value && !imagesQuery.isLoading.value;
    });

    const handleDateChange = range => {
      dateRange.value = {
        from: range.from || null,
        to: range.to || null
      };
    };

    const handleServicesChange = services => {
      selectedServices.value = services;
    };

    const handleAspectRatioChange = ratios => {
      selectedAspectRatios.value = ratios;
    };

    const clearFilters = () => {
      metadataSearchQuery.value = '';
      visualSearchQuery.value = '';
      dateRange.value = { from: null, to: null };
      selectedServices.value = [];
      selectedAspectRatios.value = [];
    };

    const handleScroll = debounce(async event => {
      const container = event.target;
      const scrollPosition = container.scrollTop + container.clientHeight;
      const scrollThreshold = container.scrollHeight - 200;

      if (scrollPosition >= scrollThreshold && !imagesQuery.isFetching.value && imagesQuery.hasNextPage.value) {
        await imagesQuery.fetchNextPage();
      }
    }, 100);

    return {
      scrollContainer,
      metadataSearchQuery,
      visualSearchQuery,
      dateRange,
      selectedServices,
      selectedAspectRatios,
      serviceOptions,
      aspectRatioOptions,
      imagesQuery,
      allImages,
      hasActiveFilters,
      shouldShowLoadingSpinner,
      handleDateChange,
      handleServicesChange,
      handleAspectRatioChange,
      clearFilters,
      handleScroll,
      currentPagination
    };
  }
};
</script>

<style lang="scss" scoped>
.gallery-container {
  @include inter-font($size: 12px, $color: var(--main-text-color));
  display: grid;
  grid-template-columns: 300px 1fr;
  height: calc(100vh - 76px);
  background: #fafafa;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
}

.sidebar {
  background: white;
  border-right: 1px solid #eee;
  padding: 16px;
  overflow-y: auto;
  height: calc(100vh - 76px);
  @media (max-width: 1024px) {
    display: none;
  }
}

.gallery-content {
  overflow-y: scroll;
}

.full-height {
  height: 100%;
}

.q-date {
  min-width: unset !important;
  width: 100%;
  margin-bottom: 12px;
}

.checkbox {
  text-align: left;
  padding: 8px 16px;
}

.input-caption {
  text-align: left;
  @include inter-font($size: 11px, $color: var(--main-text-color));
  opacity: 0.55;
  margin-bottom: 12px;
}
</style>
