import { render, staticRenderFns } from "./Gallery.vue?vue&type=template&id=c838bde2&scoped=true&"
import script from "./Gallery.vue?vue&type=script&lang=js&"
export * from "./Gallery.vue?vue&type=script&lang=js&"
import style0 from "./Gallery.vue?vue&type=style&index=0&id=c838bde2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c838bde2",
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QIcon,QDate,QExpansionItem,QOptionGroup,QBtn,QSpinnerDots});
